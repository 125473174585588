import { useGetCompany } from '@omnipkg/pa-ui-kit';
import { useQuery } from '@tanstack/react-query';

export const useGetCompanyHandler = () => {
  const getCompany = useGetCompany();
  return useQuery({
    queryKey: ['lk-shell-company'],
    queryFn: () => getCompany(),
    enabled: false,
  });
};

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { User, useUserStore } from '@omnipkg/pa-ui-kit';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import { useGetCompanyHandler } from 'src/api/getCompany';
import { useGetUserHandler } from 'src/api/getUser';
import { useGetUserLegacy } from 'src/api/getUserLegacy';

interface ContextProps {
  children: React.ReactNode;
}

interface CommonContextValue {
  isUserFetching: boolean;
  getMainData: () => Promise<void>;
  userRefetch: (
    options?: RefetchOptions,
  ) => Promise<QueryObserverResult<User, Error>>;
}

const Context = createContext<CommonContextValue | null>(null);

export const CommonContextProvider = ({ children }: ContextProps) => {
  const { setCompany, setUser, setUserLegacyUid } = useUserStore();

  const {
    data: userData,
    isFetching: isUserFetching,
    refetch: userRefetch,
  } = useGetUserHandler();

  const { data: companyData, refetch: companyRefetch } = useGetCompanyHandler();
  const { data: userLegacy, refetch: userLegacyRefetch } = useGetUserLegacy();

  const getMainData = useCallback(async () => {
    await userRefetch();
    await companyRefetch();
    userLegacyRefetch();
  }, [userRefetch, companyRefetch, userLegacyRefetch]);

  useEffect(() => {
    if (userData) {
      setUser(userData);
    }
  }, [userData, setUser]);

  useEffect(() => {
    if (companyData) {
      setCompany(companyData);
    }
  }, [companyData, setCompany]);

  useEffect(() => {
    if (userLegacy) {
      setUserLegacyUid(userLegacy.uid);
    }
  }, [userLegacy, setUserLegacyUid]);

  const value = useMemo(() => {
    return {
      isUserFetching,
      getMainData,
      userRefetch,
    };
  }, [isUserFetching, getMainData, userRefetch]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export function useCommonContext(): CommonContextValue {
  const contextData = useContext(Context);
  if (!contextData)
    throw new Error(
      'Please use hook useCommonContext inside CommonContextProvider provider',
    );
  return contextData;
}

import { lazy, Suspense } from 'react';
import { Loader } from '@omnipkg/pa-ui-kit';

import { HocProps } from '../types';

const LazyHock = lazy(() => import('../LazyHoc'));

export default function BaseHoc({ children }: HocProps): JSX.Element {
  return (
    <Suspense fallback={<Loader fullScreen />}>
      <LazyHock>{children}</LazyHock>
    </Suspense>
  );
}

import { useCallback } from 'react';
import { CommonResponse, useApiContext, User } from '@omnipkg/pa-ui-kit';
import { useQuery } from '@tanstack/react-query';

function useGetUserLegacyRequest() {
  const { request } = useApiContext();

  return useCallback(async (): Promise<User> => {
    const { data } = await request<CommonResponse<User>, Request>({
      url: 'users/current/',
    });

    return data.data;
  }, [request]);
}

export const useGetUserLegacy = () => {
  const getUserLegacy = useGetUserLegacyRequest();

  return useQuery({
    queryKey: ['lk-shell-user-legacy'],
    queryFn: () => getUserLegacy(),
    enabled: false,
  });
};

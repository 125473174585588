import { lazy, Suspense, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Loader, useAppBaseContext, useUserStore } from '@omnipkg/pa-ui-kit';
import { useCommonContext } from 'src/context/CommonContext';

export const MainLayout = lazy(() => import('../MainLayout'));

export default function InitialLayout(): JSX.Element {
  const { isAuthDataLoading, isTokenSet } = useAppBaseContext();
  const { user, company, userLegacyUid } = useUserStore();
  const { getMainData } = useCommonContext();

  const isDataReady =
    user && company && isTokenSet && !isAuthDataLoading && userLegacyUid;

  useEffect(() => {
    if (!isAuthDataLoading && isTokenSet) {
      getMainData();
    }
  }, [isAuthDataLoading, isTokenSet, getMainData]);

  return isDataReady ? (
    <Suspense fallback={<Loader fullScreen />}>
      <MainLayout>
        <Outlet />
      </MainLayout>
    </Suspense>
  ) : (
    <Loader fullScreen />
  );
}
